<script>
    export default {
        name: 'base-animation',

        data() {
            return {
                motionAccepted: false,
            };
        },

        async mounted() {
            if (window.matchMedia('screen and (prefers-reduced-motion: no-preference)').matches) {
                this.setInitialStates();
                this.motionAccepted = true;

                await this.loadGsap();

                this.reveal();
            }

            await this.$nextTick();

            this.$el.classList.remove('animation-loading');
        },

        methods: {
            /**
             * Load the gsap dependencies.
             */
            async loadGsap() {
                const { gsap } = await import('gsap/gsap-core');
                const { CSSPlugin } = await import('gsap/CSSPlugin');
                const { ScrollTrigger } = await import('gsap/ScrollTrigger');

                gsap.registerPlugin(CSSPlugin);
                gsap.registerPlugin(ScrollTrigger);

                this.gsap = gsap;
                this.scrollTrigger = ScrollTrigger;
            },

            /**
             * Reveal animation.
             */
            reveal() {
                // Overwritten by parent
            },
        },

        /**
         * Return a renderless component.
         *
         * @returns {object}
         */
        render() {
            const element = this.$scopedSlots.default({});

            // Initially opacity 0 the section so that the animation
            // happens after the javascript has been parsed.
            element.forEach((el) => {
                el.data.staticClass += ' animation-loading';
            });

            return element;
        },
    };
</script>

<style lang="scss" scoped>
    .animation-loading {
        opacity: 0;
    }
</style>
